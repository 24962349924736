import type { Pagy } from "src/lib/types";

type PaginationInfoProps = {
  pagy: Pagy | null;
};

export const PaginationInfo = ({ pagy }: PaginationInfoProps) => {
  if (!pagy) {
    return null;
  }
  const { page, series } = pagy;
  return (
    <div className="">
      <span className="text-sm text-gray-500">
        Page {page} from {series.length}
      </span>
    </div>
  );
};
