import { ArrowPathIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import React, { forwardRef } from "react";
import { ErrorOption } from "react-hook-form";

import classNames from "classnames";

import { FieldSubText } from "src/components/ui-components/inputs/FieldSubText";
import { FormLabel } from "src/components/ui-components/inputs/FormLabel";

export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  isLoading?: boolean;
  isValid?: boolean;
  error?: ErrorOption;
  subText?: React.ReactNode;
  label?: string;
}

export const TextInput = forwardRef(
  (
    { className, label, subText, isLoading, isValid, error, ...rest }: TextInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <>
        {label && (
          <FormLabel htmlFor={rest.name} required={rest.required}>
            {label}
          </FormLabel>
        )}
        <div className="relative w-full">
          {isLoading ? (
            <>
              <span className="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2">
                <ArrowPathIcon className="animate-spin text-orange-500" />
              </span>
              <div className="bg-brand-gray-light/10 absolute h-full w-full cursor-not-allowed"></div>
            </>
          ) : (
            <>
              {isValid && (
                <CheckCircleIcon className="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 text-green-500" />
              )}
              {error?.type === "manual" && (
                <ExclamationCircleIcon className="absolute right-2 top-1/2 h-6 w-6 -translate-y-1/2 text-red-500" />
              )}
            </>
          )}
          <input
            {...rest}
            ref={ref}
            className={classNames(
              "w-full rounded border py-3 pl-4 pr-12 text-black",
              !error ? "border-brand-black/40" : "border-red-500",
              className || ""
            )}
          />
        </div>
        <FieldSubText error={error}>{subText}</FieldSubText>
      </>
    );
  }
);
TextInput.displayName = "TextInput";
export default TextInput;
