import { useEffect, useState } from "react";

import {
  AuthWaitingScreen,
  AuthUnavailableScreen,
  AuthErrorScreen,
} from "src/components/features/auth/screens/AuthScreens";
import { useCoreApi } from "src/hooks";
import { useAppSession } from "src/hooks";

export function AuthSession({ children }: { children: React.ReactNode }) {
  const { authToken, setAuthToken, storedAuthSession } = useAppSession();
  const { checkHealth, postLogin } = useCoreApi();
  const [coreApiState, setCoreApiState] = useState<"pending" | "ready" | "busy" | "errored">("pending");
  const [isSessionChecked, setSessionChecked] = useState(false);

  const checkCoreApi = () => {
    setCoreApiState("pending");
    checkHealth()
      .then((ready) => {
        if (ready) {
          setCoreApiState("ready");
        } else {
          setCoreApiState("busy");
        }
      })
      .catch(() => {
        setCoreApiState("errored");
      });
  };

  useEffect(() => {
    checkCoreApi();
  }, []);

  useEffect(() => {
    if (coreApiState === "ready" && !isSessionChecked) {
      if (storedAuthSession && storedAuthSession.authToken) {
        setAuthToken(storedAuthSession.authToken);
      } else {
        setSessionChecked(true);
      }
    }
  }, [coreApiState, isSessionChecked]);

  useEffect(() => {
    if (!authToken || !storedAuthSession) return;
    postLogin({ orgId: storedAuthSession.orgId || null })
      .then(() => {
        setSessionChecked(true);
      })
      .catch(() => {
        setSessionChecked(true);
      });
  }, [authToken]);

  const content = () => {
    switch (true) {
      case coreApiState === "busy":
        return <AuthUnavailableScreen />;
      case coreApiState === "pending":
        return <AuthWaitingScreen text="Checking service availability..." />;
      case coreApiState === "errored":
        return <AuthErrorScreen code={500} onRetry={checkCoreApi} />;
      case coreApiState === "ready" && isSessionChecked:
        return children;
      default:
        return <AuthWaitingScreen text="Session restoring..." />;
    }
  };

  return <>{content()}</>;
}

export default AuthSession;
