import React from "react";

import { InfoLabel } from "src/components/ui-components/info";

type FeaturePageProps = {
  singular: string;
  plural: string;
  total?: number;
  description?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
};

export const FeaturePage = ({
  singular,
  plural,
  total,
  description,
  actions,
  children,
}: FeaturePageProps) => {
  return (
    <div className="muted-bg-color min-h-full p-2 md:p-4 lg:p-8">
      <header className="sm:flex sm:items-center sm:justify-between">
        <div>
          <div className="flex items-center gap-x-3">
            <h2 className="text-lg font-medium text-gray-800 dark:text-white">{plural}</h2>
            {total && (
              <InfoLabel
                title={
                  "You have " + (total > 1 ? `${total} ${plural}` : `${total} ${singular}`)
                }>{`${total} total`}</InfoLabel>
            )}
          </div>
          {description && <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">{description}</p>}
        </div>
        <div className="mt-4 flex items-center space-x-2">{actions}</div>
      </header>
      <div>{children}</div>
    </div>
  );
};
