import React from "react";
import { ErrorOption } from "react-hook-form";

import classNames from "classnames";

type FieldSubTextProps = {
  children?: React.ReactNode;
  className?: string;
  error?: ErrorOption;
};

export const FieldSubText = ({ children, className, error }: FieldSubTextProps) => {
  return (
    <p
      className={classNames(
        "mb-0 mt-1 text-[11px] font-normal",
        error ? "text-red-500" : "text-gray-500",
        className
      )}>
      {error?.message ? error.message : children}
    </p>
  );
};
