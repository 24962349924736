import { Route, Routes, Navigate } from "react-router-dom";

import AdminDashboard from "src/components/features/dashboard/AdminDashboard";
import { OrganizationsPage, CreateOrganizationPage } from "src/components/features/organization/pages";
import { UsersPage } from "src/components/features/users/pages";
import { NotFoundPage } from "src/components/pages";
import { useAppSelector } from "src/store";
import { getSessionIsAdminSelector } from "src/store/selectors";

export const AdminRoutes = () => {
  const isAdmin = useAppSelector(getSessionIsAdminSelector);

  return isAdmin ? (
    <Routes>
      <Route path="/" element={<Navigate to="/admin/dashboard" />} />
      <Route path="/dashboard" element={<AdminDashboard />} />
      <Route path="/organizations" element={<OrganizationsPage />} />
      <Route path="/organizations/new" element={<CreateOrganizationPage />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  ) : (
    <NotFoundPage />
  );
};
