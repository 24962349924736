import { ArrowRightEndOnRectangleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

import classNames from "classnames";

import { IconButton } from "src/components/ui-components/buttons";
import ThemeSelector from "src/components/ui-components/theme/ThemeSelector";
import { useAppSession } from "src/hooks";
import { useAppSelector } from "src/store";
import { getSessionOrganizationSelector, getSessionIsAdminSelector } from "src/store/selectors";

export default function Header({ className }: { className?: string }) {
  const isAdmin = useAppSelector(getSessionIsAdminSelector);
  const organization = useAppSelector(getSessionOrganizationSelector);
  const { cancelDiveInToOrganization } = useAppSession();
  const navigate = useNavigate();

  return (
    <header
      className={classNames(
        "main-bg-color flex h-14 shrink-0 items-center justify-between border-b border-gray-200 px-2 py-1 dark:border-gray-700",
        className
      )}>
      <div className="ml-auto flex items-center justify-center gap-2">
        {isAdmin && Boolean(organization) ? (
          <IconButton
            size="sm"
            onClick={() => {
              cancelDiveInToOrganization();
              navigate("/admin/organizations");
            }}>
            <XCircleIcon className="text-red-500" />
          </IconButton>
        ) : null}
        <ThemeSelector />
        <IconButton
          size="sm"
          onClick={() => {
            navigate("/auth/logout");
          }}>
          <ArrowRightEndOnRectangleIcon />
        </IconButton>
      </div>
    </header>
  );
}
