import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FullScreenLoaderType = {
  title: string | null;
  subTitle: string | null;
};

export type AppLoadingState = {
  globalLoading: boolean;
  fullScreenLoading: FullScreenLoaderType | null;
};

const initialState: AppLoadingState = {
  globalLoading: false,
  fullScreenLoading: null,
};

const loadingSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setAppLoadingAction: (state, action: PayloadAction<AppLoadingState>) => {
      state.globalLoading = action.payload.globalLoading;
      state.fullScreenLoading = action.payload.fullScreenLoading;
    },
    setGlobalLoadingAction: (state, action: PayloadAction<AppLoadingState["globalLoading"]>) => {
      state.globalLoading = action.payload;
    },
    setFullScreenLoadingAction: (state, action: PayloadAction<FullScreenLoaderType | null>) => {
      state.fullScreenLoading = action.payload;
    },
    setInitialAppLoadingAction: (state) => {
      state.globalLoading = false;
      state.fullScreenLoading = null;
    },
  },
});

export const {
  setAppLoadingAction,
  setGlobalLoadingAction,
  setFullScreenLoadingAction,
  setInitialAppLoadingAction,
} = loadingSlice.actions;

export default loadingSlice;
