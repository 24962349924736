import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { OrganizationForm } from "src/components/features/organization/forms/OrganizationForm";
import { useCoreApi } from "src/hooks";

export const CreateOrganizationPage = () => {
  const navigate = useNavigate();
  const { createOrganization } = useCoreApi();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data: any) => {
    setLoading(true);
    createOrganization(data)
      .then(() => {
        navigate("/admin/organizations");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div>
        <Link className="text-blue-500 hover:underline" to="/admin/organizations">
          Back to organizations
        </Link>
      </div>
      <h1 className="mb-4 text-center text-2xl">Create a new organization</h1>
      <div className="flex">
        <OrganizationForm
          className="mx-auto max-w-screen-sm"
          isLoading={loading}
          disabled={loading}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};
