export const CORE_API_NAMESPACE = "/api/v1";
export const CORE_API_ENDPOINTS = {
  healthCheck: { isAuthorized: false, url: "/health_check", adminOnly: false },
  resetPassword: { isAuthorized: false, url: CORE_API_NAMESPACE + "/password", adminOnly: false },
  invitations: { isAuthorized: false, url: CORE_API_NAMESPACE + "/invitations/accept", adminOnly: false },
  login: { isAuthorized: false, url: CORE_API_NAMESPACE + "/login", adminOnly: false },
  logout: { isAuthorized: true, url: CORE_API_NAMESPACE + "/logout", adminOnly: false },
  getMe: { isAuthorized: true, url: CORE_API_NAMESPACE + "/me", adminOnly: false },
  organizations: { isAuthorized: true, url: CORE_API_NAMESPACE + "/organizations", adminOnly: false },
  users: { isAuthorized: true, url: CORE_API_NAMESPACE + "/users", adminOnly: true },
};
