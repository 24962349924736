import { SP_APP_ENV } from "./env";

/**
 * We can allow environment type here
 */
type AppEnvironmentType = "development" | "production";

type AppEnvironmentVariables = {
  DEV_MODE: boolean;
  CORE_API_URL: string;
};

/**
 *  Define the environment variables for each environment here
 *  and use the SP_APP_ENV in npm scripts to select the correct one
 *  examples:   - "start": "SP_APP_ENV=development react-scripts start"
 *              - "build": "SP_APP_ENV=production react-scripts build"
 */
const appEnvironments: Record<AppEnvironmentType, AppEnvironmentVariables> = {
  development: {
    DEV_MODE: true,
    CORE_API_URL: "https://mysite-5pzj.onrender.com",
  },
  production: {
    DEV_MODE: false,
    CORE_API_URL: "https://production-mysite-5pzj.onrender.com",
  },
};

const environments: AppEnvironmentVariables = appEnvironments[SP_APP_ENV] || appEnvironments.development; // default to development

export { environments };
