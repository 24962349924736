import { PuzzlePieceIcon } from "@heroicons/react/24/solid";
import {
  ChartBarIcon,
  BuildingOffice2Icon,
  UsersIcon,
  WrenchScrewdriverIcon,
  UserCircleIcon,
  Bars3Icon,
} from "@heroicons/react/24/solid";
import { useState, useEffect, useCallback } from "react";
import { useLocation, Link } from "react-router-dom";

import classNames from "classnames";

import { IconButton } from "src/components/ui-components/buttons";
import type { AppSessionRole } from "src/lib/types";
import { useAppSelector } from "src/store";
import { getSessionUserSelector, getSessionRoleSelector } from "src/store/selectors";

export type NavMenuItem = {
  icon?: React.ReactNode;
  title: string;
  path: string;
};

export type NavMenuProps = {
  isCollapsed: boolean;
  onCollapse: () => void;
  className?: string;
};

export const NavMenu = ({ isCollapsed, onCollapse, className }: NavMenuProps) => {
  const location = useLocation();
  const sessionRole = useAppSelector(getSessionRoleSelector);
  const user = useAppSelector(getSessionUserSelector);
  const [navMenu, setNavMenu] = useState<NavMenuItem[]>([]);

  useEffect(() => {
    setNavMenu(getNavMenu(sessionRole));
  }, [sessionRole]);

  const getNavMenu = useCallback(
    (sessionRole: AppSessionRole | null): NavMenuItem[] => {
      if (sessionRole === null) {
        return [];
      }
      const menu: NavMenuItem[] = [
        {
          icon: <ChartBarIcon />,
          title: "Dashboard",
          path: `/${sessionRole}/dashboard`,
        },
      ];
      if (sessionRole === "admin") {
        menu.push(
          {
            icon: <BuildingOffice2Icon />,
            title: "Organizations",
            path: "/admin/organizations",
          },
          {
            icon: <UsersIcon />,
            title: "Users",
            path: "/admin/users",
          }
        );
      }
      if (sessionRole === "org") {
        menu.push(
          {
            icon: <WrenchScrewdriverIcon />,
            title: "Orders",
            path: "/org/orders",
          },
          {
            icon: <UsersIcon />,
            title: "Customers",
            path: "/org/customers",
          }
        );
      }
      return menu;
    },
    [sessionRole]
  );

  return (
    <aside
      className={classNames(
        "fixed bottom-0 left-0 z-50 flex w-full shrink-0 flex-col bg-brand-gray md:sticky md:top-0 md:h-dvh md:overflow-x-auto",
        className
      )}>
      <MenuSection isCollapsed={isCollapsed}>
        <IconButton onClick={onCollapse}>
          <Bars3Icon />
        </IconButton>
        <span className={classNames("ml-2 pt-1 font-oxanium text-gray-300", isCollapsed && "hidden")}>
          ServicePOINT
        </span>
      </MenuSection>
      {user && (
        <MenuSection isCollapsed={isCollapsed}>
          <Link
            to={`${sessionRole}/profile`}
            className={classNames("flex items-center", !isCollapsed && "w-full")}>
            <div
              className={classNames(
                "h-8 w-8 shrink-0",
                sessionRole === "admin" && "text-orange-400",
                sessionRole === "org" && "text-cyan-400",
                sessionRole === "user" && "text-gray-200"
              )}>
              <UserCircleIcon />
            </div>
            <p className={classNames("relative ml-2 flex-1 text-gray-300", isCollapsed && "hidden")}>
              <span className="truncate text-sm">{user.full_name || user.email}</span>
              {user.admin && (
                <span className="absolute right-0 top-0 inline-block -translate-y-full text-[8px] text-orange-400">
                  admin
                </span>
              )}
            </p>
          </Link>
        </MenuSection>
      )}
      <nav className="overflow-y-auto p-2">
        <ul className="flex h-full flex-row justify-center md:flex-col md:items-start md:justify-start">
          {navMenu.map((item, index) => {
            const isActive = location.pathname === item.path;
            return (
              <li key={index} className="mx-2 flex w-20 text-sm md:mx-0 md:mb-1 md:w-full">
                <Link
                  to={item.path}
                  className={classNames(
                    "flex w-full flex-col items-center rounded-md p-1 md:flex-row md:p-2",
                    "text-gray-300 hover:bg-gray-600 dark:hover:bg-gray-700",
                    isActive && "bg-gray-600 text-gray-100 dark:bg-gray-700",
                    isCollapsed && "justify-center"
                  )}>
                  <span className={classNames("h-6 w-6 md:h-4 md:w-4")}>
                    {item.icon || <PuzzlePieceIcon />}
                  </span>
                  <span
                    className={classNames(
                      "w-full truncate text-center text-xs md:ml-2 md:block md:text-left md:text-sm",
                      isCollapsed && "md:!hidden"
                    )}>
                    {item.title}
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </aside>
  );
};

const MenuSection = ({ isCollapsed, children }: { isCollapsed: boolean; children: React.ReactNode }) => {
  return (
    <div
      className={classNames(
        "relative hidden h-14 shrink-0 items-center overflow-hidden border-b border-gray-500 px-2 leading-none md:flex dark:border-gray-700",
        isCollapsed && "justify-center"
      )}>
      {children}
    </div>
  );
};

export default NavMenu;
