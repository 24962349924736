import { Route, Routes, Navigate } from "react-router-dom";

import { NotFoundPage } from "src/components/pages";
import { AppProtectedRoute } from "src/router/AppRouting";
import { AuthRoutes } from "src/router/routes";
import { AdminRoutes, OrganizationRoutes } from "src/router/routes-by-role";
import { useAppSelector } from "src/store";
import { getSessionRoleSelector } from "src/store/selectors";

export default function RootRouter() {
  const sessionRole = useAppSelector(getSessionRoleSelector);
  const defaultPath = sessionRole ? `/${sessionRole}/dashboard` : "/auth";
  return (
    <Routes>
      <Route path="/" element={<Navigate to={defaultPath} />} />
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route
        path="/admin/*"
        element={
          <AppProtectedRoute>
            <AdminRoutes />
          </AppProtectedRoute>
        }
      />
      <Route
        path="/org/*"
        element={
          <AppProtectedRoute>
            <OrganizationRoutes />
          </AppProtectedRoute>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
