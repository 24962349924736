import { Controller, SubmitHandler, useForm } from "react-hook-form";

import classNames from "classnames";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "src/components/ui-components/buttons";
import { TextInput } from "src/components/ui-components/inputs/TextInput";

function getValidationSchema() {
  return z.object({
    slug: z
      .string()
      .min(5, `Slug must be at least ${5} characters`)
      .max(100, `Slug must be at most ${100} characters`)
      .regex(
        /^[a-z0-9-]+$/,
        "Slug must only contain lowercase English letters, numbers, and hyphens, with no spaces or special characters"
      ),
    name: z
      .string()
      .min(3, `Name must be at least ${3} characters`)
      .max(100, `Name must be at most ${100} characters`),
    owner_email: z.string().email("Invalid email address"),
  });
}

export type OrganizationFormData = {
  slug: string;
  name: string;
  owner_email: string;
};

export type OrganizationFormProps = {
  onSubmit: (data: OrganizationFormData) => void;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
};

export const OrganizationForm = ({ onSubmit, className, isLoading, disabled }: OrganizationFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getValidationSchema()),
    mode: "onTouched",
    defaultValues: {
      slug: "",
      name: "",
      owner_email: "",
    },
  });

  const submit: SubmitHandler<OrganizationFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <fieldset disabled={disabled || isLoading}>
        <div className="mb-4">
          <Controller
            name="slug"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                required
                type="text"
                label="Slug"
                subText="Enter a unique slug for this organization"
                error={errors.slug}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                required
                type="text"
                label="Name"
                subText="Enter the name of this organization"
                error={errors.name}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller
            name="owner_email"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                required
                type="email"
                label="Owner Email"
                subText="We send an invite to this email"
                error={errors.owner_email}
              />
            )}
          />
        </div>
      </fieldset>
      <div className="flex justify-center">
        <Button disabled={!isValid || disabled || isLoading} type="submit" size="md">
          Submit
        </Button>
      </div>
    </form>
  );
};
