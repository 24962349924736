import { Cog6ToothIcon, MoonIcon, SunIcon } from "@heroicons/react/24/solid";

import classNames from "classnames";

import { IconButton } from "src/components/ui-components/buttons";
import { useAppearance } from "src/hooks";
import type { AppAppearanceState } from "src/store/slices/appearanceSlice";

const ICONS: Record<AppAppearanceState["currentTheme"], React.ReactNode> = {
  light: <SunIcon />,
  dark: <MoonIcon />,
  system: <Cog6ToothIcon />,
};

export default function ThemeSelector({ className }: { className?: string }) {
  const { currentTheme, allowedThemes, selectTheme } = useAppearance();
  return (
    <div className={classNames("flex items-center justify-center space-x-2", className)}>
      <div className={classNames("relative")}>
        <IconButton
          size="sm"
          aria-label="Select theme"
          onClick={() => {
            const currentIndex = allowedThemes.findIndex((theme) => theme.value === currentTheme);
            const nextIndex = (currentIndex + 1) % allowedThemes.length;
            selectTheme(allowedThemes[nextIndex].value);
          }}>
          {ICONS[currentTheme]}
        </IconButton>
      </div>
    </div>
  );
}
