import PageWithSideNav from "src/components/layout/components/PageWithSideNav";

type OrganizationLayoutProps = {
  children: React.ReactNode;
};

export function OrganizationLayout({ children }: OrganizationLayoutProps) {
  return <PageWithSideNav>{children}</PageWithSideNav>;
}

export default OrganizationLayout;
