import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import classNames from "classnames";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "src/components/ui-components/buttons";
import { TextInput } from "src/components/ui-components/inputs/TextInput";

export type ResetPasswordFormData = {
  email: string;
};

function getValidationSchema() {
  return z.object({
    email: z.string().email("Invalid email address"),
  });
}

export type ResetPasswordFormProps = {
  formData?: ResetPasswordFormData;
  onSubmit: (data: ResetPasswordFormData) => void;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
};

export const ResetPasswordForm = ({
  formData,
  disabled,
  isLoading,
  className,
  onSubmit,
}: ResetPasswordFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getValidationSchema()),
    mode: "onTouched",
    defaultValues: formData || {
      email: "",
    },
  });

  const submit: SubmitHandler<ResetPasswordFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <fieldset>
        <div className="mb-4">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                required
                type="email"
                label="Email"
                subText="Provide your email address"
                error={errors.email}
              />
            )}
          />
        </div>
      </fieldset>
      <div className="mb-8 flex justify-end space-x-2 text-sm">
        <Link to="/auth/login" className="text-blue-500 underline">
          Back to Login
        </Link>
      </div>
      <div className="flex justify-center">
        <Button disabled={!isValid || disabled || isLoading} type="submit">
          Send email
        </Button>
      </div>
    </form>
  );
};
