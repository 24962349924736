import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useSearchParams } from "react-router-dom";

import classNames from "classnames";

import { Button } from "src/components/ui-components/buttons";
import type { ButtonProps } from "src/components/ui-components/buttons";
import type { Pagy } from "src/lib/types";

const PageButton = ({
  active,
  className,
  children,
  ...rest
}: {
  active?: boolean;
} & ButtonProps) => {
  return (
    <Button
      size="sm"
      {...rest}
      className={classNames("min-w-9 capitalize", active && "font-medium underline", className)}>
      {children}
    </Button>
  );
};

const PaginationContainer = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return <div className={classNames("flex justify-between space-x-2", className)}>{children}</div>;
};

export type PaginationProps = {
  pagy: Pagy | null;
  onPageChange: (page: number) => void;
  className?: string;
};

export const Pagination = ({ pagy, onPageChange, className }: PaginationProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") || "1");

  if (!pagy) {
    return null;
  }

  const handleNavigation = (page: number) => {
    setSearchParams({ page: page.toString() });
    onPageChange(page);
  };

  const renderPageButtons = () => {
    const pages = pagy.series;
    const pageButtons: React.ReactNode[] = [];

    // Define ranges to show in pagination
    const showEllipsis = (index: number, array: string[] | number[]) => {
      return index > 0 && index < array.length - 1;
    };

    pages.forEach((p: string | number, index: number) => {
      const page = typeof p === "string" ? parseInt(p) : p;
      if (index === 0 || index === pages.length - 1) {
        pageButtons.push(
          <PageButton key={page} onClick={() => handleNavigation(page)} active={currentPage === page}>
            {page}
          </PageButton>
        );
      } else if (page >= currentPage - 2 && page <= currentPage + 2) {
        pageButtons.push(
          <PageButton key={page} onClick={() => handleNavigation(page)} active={currentPage === page}>
            {page}
          </PageButton>
        );
      } else if (showEllipsis(index, pages)) {
        if (pageButtons[pageButtons.length - 1] !== "...") {
          pageButtons.push(
            <span key={`ellipsis-${index}`} className="mx-1">
              .
            </span>
          );
        }
      }
    });

    return pageButtons;
  };

  return (
    <div className={classNames(className)}>
      <PaginationContainer>
        <PageButton onClick={() => handleNavigation(currentPage - 1)} disabled={!pagy.prev}>
          <ArrowLongLeftIcon className="h-5" />
          <span className="ml-1 hidden md:block">Previous</span>
        </PageButton>
        <PaginationContainer>{renderPageButtons()}</PaginationContainer>
        <PageButton onClick={() => handleNavigation(currentPage + 1)} disabled={!pagy.next}>
          <span className="mr-1 hidden md:block">Next</span>
          <ArrowLongRightIcon className="h-5" />
        </PageButton>
      </PaginationContainer>
    </div>
  );
};
