import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import classNames from "classnames";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "src/components/ui-components/buttons";
import { TextInput } from "src/components/ui-components/inputs/TextInput";
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from "src/lib/constants";
import type { LoginPayload } from "src/lib/types";

export type LoginFormData = LoginPayload["user"];

function getValidationSchema() {
  return z.object({
    email: z.string().email("Invalid email address"),
    password: z
      .string()
      .min(PASSWORD_MIN_LENGTH, `Password must be at least ${PASSWORD_MIN_LENGTH} characters`)
      .max(PASSWORD_MAX_LENGTH, `Password must be at most ${PASSWORD_MAX_LENGTH} characters`),
  });
}

export type LoginFormProps = {
  formData?: LoginFormData;
  onSubmit: (data: LoginFormData) => void;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
};

export const LoginForm = ({ disabled, isLoading, className, onSubmit }: LoginFormProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getValidationSchema()),
    mode: "onTouched",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const submit: SubmitHandler<LoginFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <fieldset>
        <div className="mb-4">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                required
                type="email"
                label="Email"
                subText="Provide your email address to login"
                error={errors.email}
              />
            )}
          />
        </div>
        <div className="mb-2">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                required
                type={isPasswordVisible ? "text" : "password"}
                label="Password"
                subText="Provide your password to login"
                error={errors.password}
              />
            )}
          />
        </div>
      </fieldset>
      <div className="mb-3 flex items-center justify-between space-x-2">
        <button
          type="button"
          className="text-sm text-blue-500 underline"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
          {isPasswordVisible ? "Hide" : "Show"} password
        </button>
        <span className="inline-block h-6 items-center space-x-2">
          {isPasswordVisible ? <EyeIcon className="h-full" /> : <EyeSlashIcon className="h-full" />}
        </span>
      </div>
      <div className="mb-8 flex justify-end space-x-2 text-sm">
        <Link
          to="/auth/reset-password"
          state={{ email: isValid ? getValues("email") : null }}
          className="text-blue-500 underline">
          Forgot password
        </Link>
      </div>
      <div className="flex justify-center">
        <Button disabled={!isValid || disabled || isLoading} type="submit" size="md">
          Login
        </Button>
      </div>
    </form>
  );
};
