import React from "react";

import classNames from "classnames";

export type ButtonProps = {
  size?: "sm" | "md" | "lg";
  color?: "primary";
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({ size = "md", color = "primary", children, className, ...rest }: ButtonProps) => {
  return (
    <button
      {...rest}
      className={classNames(
        "flex items-center justify-center space-x-2 font-light leading-none",
        color === "primary" && "primary-button-colors",
        size === "sm" && "rounded-md px-3 py-1.5 text-xs",
        size === "md" && "rounded-lg px-6 py-2",
        size === "lg" && "rounded-md px-10 py-3",
        className
      )}>
      {children}
    </button>
  );
};
