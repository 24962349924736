import { saveSelectedTheme } from "src/lib/utils";
import { ALLOWED_THEMES } from "src/lib/utils";
import { useAppSelector } from "src/store";
import { useAppDispatch } from "src/store";
import { getAppearanceSelector } from "src/store/selectors";
import type { AppAppearanceState } from "src/store/slices/appearanceSlice";
import { setCurrentThemeAction } from "src/store/slices/appearanceSlice";

export const useAppearance = () => {
  const dispatch = useAppDispatch();
  const { currentTheme, systemTheme } = useAppSelector(getAppearanceSelector);

  const selectTheme = (theme: AppAppearanceState["currentTheme"]) => {
    dispatch(setCurrentThemeAction(theme));
    saveSelectedTheme(theme);
  };

  return {
    currentTheme,
    systemTheme,
    allowedThemes: ALLOWED_THEMES,
    selectTheme,
  };
};
