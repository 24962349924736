import classNames from "classnames";

export type AuthFormContainerProps = {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  className?: string;
};

export const AuthFormContainer = ({ children, title, subTitle, className }: AuthFormContainerProps) => {
  return (
    <div className={classNames("w-full max-w-md", className)}>
      <div className="muted-bg-color rounded-lg p-8 shadow-lg">
        <div className="pb-4">
          {title && <h2 className="mb-4 text-center text-3xl font-bold">{title}</h2>}
          {subTitle && <div className="mt-4 text-center">{subTitle}</div>}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};
export default AuthFormContainer;
