import classNames from "classnames";

export const LoaderBar = ({ className }: { className?: string }) => {
  return (
    <div className={classNames("w-full", className)}>
      <div className="h-1 w-full overflow-hidden">
        <div className="progress left-right h-full w-full bg-cyan-500"></div>
      </div>
    </div>
  );
};
