import React from "react";

import classNames from "classnames";

type IconButtonProps = {
  size?: "sm" | "md" | "lg";
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton = ({ size = "md", children, className, ...rest }: IconButtonProps) => {
  return (
    <button
      {...rest}
      className={classNames(
        "flex rounded-md text-muted-text hover:text-primary-text",
        size === "sm" && "h-5 w-5",
        size === "md" && "h-6 w-6",
        size === "lg" && "h-8 w-8",
        className
      )}>
      {children}
    </button>
  );
};
