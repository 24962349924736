import { useCallback } from "react";

import { PublicLayout, AdminLayout, OrganizationLayout, CustomerLayout } from "src/components/layout";
import { useAppSelector } from "src/store";
import { getSessionIsAuthenticatedSelector, getSessionRoleSelector } from "src/store/selectors";

export function AppLayout({ children }: { children: React.ReactNode }) {
  const isAuthenticated = useAppSelector(getSessionIsAuthenticatedSelector);
  const sessionRole = useAppSelector(getSessionRoleSelector);

  const layout = useCallback(() => {
    switch (true) {
      case isAuthenticated && sessionRole === "admin":
        return <AdminLayout>{children}</AdminLayout>;
      case isAuthenticated && sessionRole === "org":
        return <OrganizationLayout>{children}</OrganizationLayout>;
      case isAuthenticated && sessionRole === "user":
        return <CustomerLayout>{children}</CustomerLayout>;
      default:
        return <PublicLayout>{children}</PublicLayout>;
    }
  }, [isAuthenticated, sessionRole, children]);

  return <main className="">{layout()}</main>;
}

export default AppLayout;
