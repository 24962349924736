import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { isAxiosError } from "axios";
import classNames from "classnames";

import AuthFormContainer from "src/components/features/auth/components/AuthFormContainer";
import { AuthScreen } from "src/components/features/auth/screens/AuthScreens";
import { ResetPasswordForm } from "src/components/forms";
import type { ResetPasswordFormData } from "src/components/forms";
import { Button } from "src/components/ui-components/buttons";
import { useQueryParams } from "src/hooks";
import { useCoreApi } from "src/hooks";

const EMAIL_SENT_PARAM = "emailSent";

type TranslationItem = {
  i18nKey: string;
  defaults: string;
};
type PageStatus = "initial" | "sent" | "error";

const getTitles = (status: PageStatus): { title: TranslationItem } => {
  switch (status) {
    case "initial":
      return {
        title: {
          i18nKey: "todo_add_this_key",
          defaults: "Reset password",
        },
      };
    case "sent":
      return {
        title: {
          i18nKey: "todo_add_this_key",
          defaults: "Email sent!",
        },
      };
    case "error":
      return {
        title: {
          i18nKey: "todo_add_this_key",
          defaults: "Error",
        },
      };
  }
};

const getErrorMessage = (status?: number): TranslationItem => {
  switch (status) {
    case 200:
      return { i18nKey: "todo_add_this_key", defaults: "Password reset instructions sent to your email" };
    case 422:
      return { i18nKey: "todo_add_this_key", defaults: "Email not found" };
    case 429:
      return {
        i18nKey: "todo_add_this_key",
        defaults: "Password reset email already sent. Please wait for some time before requesting again.",
      };
    default:
      return { i18nKey: "todo_add_this_key", defaults: "Something went wrong" };
  }
};

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sendResetPasswordEmail } = useCoreApi();
  const { getParam } = useQueryParams();
  const [loading, setLoading] = useState(false);
  const emailFromState = location.state?.email || "";

  const [status, setStatus] = useState<PageStatus>("initial");
  const [message, setMessage] = useState<string>("Please enter your email address");

  useEffect(() => {
    if (getParam(EMAIL_SENT_PARAM) === "true") {
      setStatus("sent");
    }
  }, []);

  const onSubmit = ({ email }: ResetPasswordFormData) => {
    setLoading(true);
    sendResetPasswordEmail(email)
      .then((response) => {
        // 200: "Password reset instructions sent to #{email}""
        const message = response.data.message;
        setMessage(message || getErrorMessage(200).defaults);
        setStatus("sent");
      })
      .catch((error: unknown) => {
        // 422: "Email not found",
        // 429: "Password reset email already sent. Please wait #{remaining_time} seconds before requesting again." with 'retry-after': <remaining_time> Header
        const status = isAxiosError(error) ? error.response?.status : null;
        const message = isAxiosError(error)
          ? error.response?.data.error || error.response?.data.message
          : null;
        switch (status) {
          case 422:
            setMessage(message || getErrorMessage(status).defaults);
            setStatus("error");
            break;
          case 429:
            setMessage(message || getErrorMessage(status).defaults);
            break;
          default:
            setStatus("error");
            setMessage(message || getErrorMessage().defaults);
            break;
        }
      })
      .finally(() => setLoading(false));
  };

  const { title } = getTitles(status);

  return (
    <AuthScreen>
      <AuthFormContainer
        title={title.defaults}
        subTitle={<span className={classNames(status === "error" && "text-red-500")}>{message}</span>}>
        {status === "initial" && (
          <ResetPasswordForm formData={{ email: emailFromState }} onSubmit={onSubmit} isLoading={loading} />
        )}
        {status === "error" && (
          <div className="flex justify-center pt-5">
            <Button onClick={() => navigate({ pathname: "/auth/login" }, { replace: true })}>Login</Button>
          </div>
        )}
      </AuthFormContainer>
    </AuthScreen>
  );
};
