import React, { createContext, useState, useRef, ReactElement, useEffect } from "react";

import { DropDownMenu } from "src/components/ui-components/dropdowns/GlobalDropdown";
import type { DropDownMenuProps } from "src/components/ui-components/dropdowns/GlobalDropdown";
import { AppLoading } from "src/hoc";

type AppGlobalLayerContextType = {
  toggleDropdown: (props: DropDownMenuProps | null, triggerRef?: HTMLElement) => void;
};

export const AppGlobalLayerContext = createContext<AppGlobalLayerContextType | null>(null);

export const AppGlobalLayer = ({ children }: { children: React.ReactNode }) => {
  const [dropdown, setDropdown] = useState<ReactElement<DropDownMenuProps> | null>(null);
  const triggerRef = useRef<HTMLElement | null>(null);
  const scrollableAncestorsRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    const findScrollableAncestors = (element: HTMLElement) => {
      const scrollableAncestors: HTMLElement[] = [];
      let currentElement: HTMLElement | null = element;
      while (currentElement && currentElement !== document.body) {
        const overflowY = window.getComputedStyle(currentElement).overflowY;
        if (
          overflowY === "scroll" ||
          overflowY === "auto" ||
          currentElement.scrollHeight > currentElement.clientHeight
        ) {
          scrollableAncestors.push(currentElement);
        }
        currentElement = currentElement.parentElement;
      }
      return scrollableAncestors;
    };

    // Handle click outside dropdown to close it
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.closest("[data-role='global-dropdown-trigger']")) {
        return;
      }
      toggleDropdown(null);
    };

    // Handle scroll event for all scrollable ancestors
    const handleScroll = () => {
      if (dropdown && triggerRef.current) {
        const buttonRect = triggerRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        let position: { top?: number; bottom?: number; left?: number; right?: number } = {
          top: buttonRect.bottom,
          right: windowWidth - buttonRect.right,
        };
        if (buttonRect.top < windowHeight / 2) {
          if (buttonRect.left > windowWidth / 2) {
            position = { top: buttonRect.bottom, right: windowWidth - buttonRect.right };
          } else {
            position = { top: buttonRect.bottom, left: buttonRect.left };
          }
        } else {
          if (buttonRect.left > windowWidth / 2) {
            position = { bottom: windowHeight - buttonRect.top, right: windowWidth - buttonRect.right };
          } else {
            position = { bottom: windowHeight - buttonRect.top, left: buttonRect.left };
          }
        }
        setDropdown(React.cloneElement(dropdown, { position }));
      }
    };

    document.addEventListener("click", handleClick);

    if (triggerRef.current) {
      scrollableAncestorsRef.current = findScrollableAncestors(triggerRef.current);
      scrollableAncestorsRef.current.forEach((ancestor) => ancestor.addEventListener("scroll", handleScroll));
    }

    return () => {
      document.removeEventListener("click", handleClick);
      scrollableAncestorsRef.current.forEach((ancestor) =>
        ancestor.removeEventListener("scroll", handleScroll)
      );
    };
  }, [dropdown]);

  const toggleDropdown = (props: DropDownMenuProps | null, newTriggerRef?: HTMLElement) => {
    if (props && newTriggerRef) {
      // If dropdown is already open, close it
      if (triggerRef.current === newTriggerRef) {
        setDropdown(null);
        triggerRef.current = null;
      } else {
        // Open new dropdown
        triggerRef.current = newTriggerRef;
        setDropdown(<DropDownMenu {...props} />);
      }
    } else {
      // Close dropdown if props is null
      setDropdown(null);
      triggerRef.current = null;
    }
  };

  return (
    <AppGlobalLayerContext.Provider
      value={{
        toggleDropdown,
      }}>
      {dropdown}
      <AppLoading>{children}</AppLoading>
    </AppGlobalLayerContext.Provider>
  );
};

export default AppGlobalLayer;
