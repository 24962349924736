import classNames from "classnames";

export type CircleSpinnerProps = {
  size?: "small" | "medium" | "large";
  className?: string;
};

const getSpinnerSize = (size: CircleSpinnerProps["size"]) => {
  switch (size) {
    case "small":
      return "w-8 h-8";
    case "medium":
      return "w-12 h-12";
    case "large":
      return "w-24 h-24";
    default:
      return "";
  }
};

const CircleSpinner = ({ size = "small", className }: CircleSpinnerProps) => {
  return (
    <div className={classNames("relative", getSpinnerSize(size), className)}>
      <svg
        className="absolute left-0 top-0 m-auto h-full w-full animate-spin"
        style={{ animationDuration: "2.5s" }}
        viewBox="25 25 50 50">
        <circle
          cx="50"
          cy="50"
          r="20"
          fill="none"
          style={{ animation: "dash 1.5s ease-in-out infinite, spinner-colors 6s ease-in-out infinite" }}
          strokeDasharray="1, 200"
          strokeDashoffset="0"
          strokeLinecap="round"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
};

export default CircleSpinner;
