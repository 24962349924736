import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSystemTheme, getSavedTheme } from "src/lib/utils";

export type AppAppearanceState = {
  currentTheme: "light" | "dark" | "system";
  systemTheme: "dark" | "light";
};

const initialState: AppAppearanceState = {
  currentTheme: getSavedTheme() || "system",
  systemTheme: getSystemTheme(),
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setCurrentThemeAction: (state, action: PayloadAction<AppAppearanceState["currentTheme"]>) => {
      state.currentTheme = action.payload;
    },
    setSystemThemeAction: (state, action: PayloadAction<AppAppearanceState["systemTheme"]>) => {
      state.systemTheme = action.payload;
    },
    setPartialThemeAction: (state, action: PayloadAction<Partial<AppAppearanceState>>) => {
      if (action.payload.currentTheme) {
        state.currentTheme = action.payload.currentTheme;
      }
      if (action.payload.systemTheme) {
        state.systemTheme = action.payload.systemTheme;
      }
    },
    setInitialAppearanceState: (state) => {
      state.currentTheme = "system";
      state.systemTheme = getSystemTheme();
    },
  },
});

export const {
  setCurrentThemeAction,
  setSystemThemeAction,
  setPartialThemeAction,
  setInitialAppearanceState,
} = sessionSlice.actions;

export default sessionSlice;
