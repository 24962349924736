import { STORAGE_KEYS } from "src/lib/constants";
import { AppAppearanceState } from "src/store/slices/appearanceSlice";

export type Theme = { label: string; value: AppAppearanceState["currentTheme"] };

export const ALLOWED_THEMES: Theme[] = [
  {
    label: "Light",
    value: "light",
  },
  {
    label: "Dark",
    value: "dark",
  },
  {
    label: "System",
    value: "system",
  },
];

export const getSystemTheme = (): "dark" | "light" => {
  if (typeof window !== "undefined") {
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  } else {
    return "light";
  }
};

export const getSavedTheme = (): AppAppearanceState["currentTheme"] | null => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(STORAGE_KEYS.APP_THEME) as AppAppearanceState["currentTheme"];
  } else {
    return null;
  }
};

export const saveSelectedTheme = (theme: AppAppearanceState["currentTheme"]): void => {
  if (typeof window !== "undefined") {
    return localStorage.setItem(STORAGE_KEYS.APP_THEME, theme);
  }
};

export const saveNavbarState = (state: boolean) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(STORAGE_KEYS.APP_NAVBAR, state ? "true" : "false");
  }
};

export const getSavedNavbarState = (): boolean => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(STORAGE_KEYS.APP_NAVBAR) === "true";
  }
  return false;
};
