import { useAppSelector } from "src/store";
import { getSessionSelector } from "src/store/selectors";

const AdminDashboard = () => {
  const sessionData = useAppSelector(getSessionSelector);
  return (
    <div>
      <h1>Admin Dashboard</h1>
      <div className="p-3">
        {sessionData.user && (
          <>
            <p className="mb-4 font-bold">User info:</p>
            <pre className="mb-5 font-mono text-xs">
              <code>{JSON.stringify(sessionData.user, null, 2)}</code>
            </pre>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
