import classNames from "classnames";

import type { DropDownProps } from "src/components/ui-components/dropdowns";
import { DropdownTrigger } from "src/components/ui-components/dropdowns/GlobalDropdown";

export type TableColumn = {
  key: string;
  content: string | React.ReactNode;
};

export type TableCell = {
  key: string;
  content: string | React.ReactNode;
  value?: string | number | boolean | null | undefined;
};

export type TableRow = {
  id: number | string;
  cells: TableCell[];
  actions?: DropDownProps["items"];
};

export type TableProps = {
  className?: string;
  perPage: number;
  loading: boolean;
  noActions?: boolean;
  cols: TableColumn[];
  rows: TableRow[];
};

const CELL_SPACING = "py-2 px-4 py-2 sm:px-6 md:px-8 lg:px-12";

export const Table = ({ className, perPage, noActions = false, loading, cols, rows }: TableProps) => {
  return (
    <div className={classNames("mt-6 flex flex-col", className)}>
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden rounded-md border border-gray-200 md:rounded-xl dark:border-gray-700">
            <table className="min-w-full divide-y divide-gray-200 border border-gray-200 dark:divide-gray-700 dark:border-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  {cols.map((col) => (
                    <th
                      key={col.key}
                      scope="col"
                      className={classNames(
                        CELL_SPACING,
                        "py-5 text-left text-sm font-normal text-gray-500 rtl:text-right dark:text-gray-400"
                      )}>
                      {col.content}
                    </th>
                  ))}
                  {!noActions && (
                    <th scope="col">
                      <span className="sr-only">Edit</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900">
                {loading ? (
                  <>
                    {new Array(perPage).fill("").map((_, i) => (
                      <tr key={i} className="animate-pulse bg-gray-50 dark:bg-gray-800">
                        {cols.map((col) => (
                          <td
                            key={col.key}
                            className={classNames(
                              CELL_SPACING,
                              "animate-pulse whitespace-nowrap text-sm font-medium"
                            )}>
                            <span className="opacity-0">loading</span>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    {rows.map((row) => (
                      <tr key={row.id} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                        {row.cells.map((cell) => (
                          <td
                            key={cell.key}
                            className={classNames(
                              CELL_SPACING,
                              "text-sm font-normal text-gray-800 dark:text-gray-200"
                            )}>
                            {cell.content}
                          </td>
                        ))}
                        {row.actions?.length ? (
                          <td
                            key="actions"
                            className="px-2 py-4 text-center text-sm font-normal text-gray-800 dark:text-gray-200">
                            <DropdownTrigger items={row.actions} />
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
