import { useCallback } from "react";

import type { AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders } from "axios";
import { isAxiosError } from "axios";

import { CORE_API_ENDPOINTS } from "./constants";
import {
  handleLoginResponse,
  handleGetMeResponse,
  handleOrganizationResponse,
  handleGetUsersResponse,
} from "./response-handlers";
import { environments } from "src/environments/environments";
import { useHttpService, useAppSession } from "src/hooks";
import type {
  LoginPayload,
  LoginResponse,
  User,
  Organization,
  CreateOrganizationPayload,
  OrganizationsResponse,
  UsersResponse,
  CreatePasswordPayload,
} from "src/lib/types";
import { appDevLog } from "src/lib/utils";
import { useAppSelector } from "src/store";
import { getSessionOrganizationSelector, getSessionRoleSelector } from "src/store/selectors";

const CORE_API_URL = environments.CORE_API_URL;
export type EndpointKey = keyof typeof CORE_API_ENDPOINTS;
type GetRequestConfigsParams = {
  endpointKey: EndpointKey;
  requestPayload?: Record<string, unknown>;
  requestConfig?: AxiosRequestConfig;
};

type GetRequestConfigsResponse = {
  url: string;
  payload: Record<string, unknown>;
  configs: AxiosRequestConfig & { headers: RawAxiosRequestHeaders };
};

export function useCoreApi() {
  const { authToken, setAuthToken, setAuthSessionData, destroyAuthSession } = useAppSession();
  const sessionOrganization = useAppSelector(getSessionOrganizationSelector);
  const sessionRole = useAppSelector(getSessionRoleSelector);
  const { post, get, patch, deleteMethod } = useHttpService();
  const serviceLog = useCallback((...data: unknown[]) => {
    appDevLog("ℹ️ Core API service says: ", ...data);
  }, []);

  const getRequestConfigs = useCallback(
    async ({
      endpointKey,
      requestPayload,
      requestConfig,
    }: GetRequestConfigsParams): Promise<GetRequestConfigsResponse> => {
      return new Promise((resolve, reject) => {
        const endpoint = CORE_API_ENDPOINTS[endpointKey];
        if (endpoint.isAuthorized && !authToken) {
          reject("Unauthorized request, authToken is not found");
          return;
        }
        if (endpoint.adminOnly && sessionRole !== "admin") {
          reject("Unauthorized request, user is not an admin");
          return;
        }
        const toReturn: GetRequestConfigsResponse = {
          url: CORE_API_URL + endpoint.url,
          payload: requestPayload ? { ...requestPayload } : {},
          configs: {
            ...requestConfig,
            headers: {
              "Content-Type": "application/json",
            },
          },
        };
        const headersToken = toReturn.configs.headers["Authorization"];
        if (headersToken && typeof headersToken === "string" && headersToken.length > 10) {
          resolve(toReturn);
          return;
        }
        if (endpoint.isAuthorized && authToken) {
          toReturn.configs.headers["Authorization"] = authToken;
          resolve(toReturn);
        } else {
          resolve(toReturn);
        }
      });
    },
    [authToken]
  );

  const handleError = useCallback(
    (error: unknown) => {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        switch (status) {
          case 401:
            serviceLog("response error, status 401, token is missed: ", error);
            destroyAuthSession();
            break;
          case 403:
            serviceLog("response error, status 403, the token is expired: ", error);
            break;
          default:
            serviceLog(`response error, status ${status}: `, error);
            break;
        }
      } else {
        serviceLog("service error: ", error);
      }
    },
    [serviceLog, destroyAuthSession]
  );

  const checkHealth = async (): Promise<boolean> => {
    try {
      const { url, configs } = await getRequestConfigs({ endpointKey: "healthCheck" });
      const response = await get(url, configs);
      if (response.status === 200) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    } catch (error: unknown) {
      return Promise.reject(error);
    }
  };

  const createPassword = async ({
    endpointKey,
    data,
  }: {
    endpointKey: "invitations" | "resetPassword";
    data: CreatePasswordPayload;
  }): Promise<LoginResponse> => {
    try {
      const { url, payload, configs } = await getRequestConfigs({
        endpointKey,
        requestPayload: { data: data },
      });
      const response =
        endpointKey === "resetPassword"
          ? await patch<LoginResponse>(url, payload, configs)
          : await post<LoginResponse>(url, payload, configs);
      const { token, user, message } = handleLoginResponse(response);
      if (token && user) {
        setAuthToken(token);
        return Promise.resolve({ user, message });
      } else {
        return Promise.reject(new Error("password creation failed"));
      }
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const sendResetPasswordEmail = async (email: string): Promise<AxiosResponse> => {
    try {
      const { url, payload, configs } = await getRequestConfigs({
        endpointKey: "resetPassword",
        requestPayload: { email: email },
      });
      const response = await post<AxiosResponse>(url, payload, configs);
      if (response.status === 200) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(new Error("Email sending failed"));
      }
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const login = async (data: LoginPayload["user"]): Promise<LoginResponse> => {
    try {
      const { url, configs, payload } = await getRequestConfigs({
        endpointKey: "login",
        requestPayload: {
          user: {
            email: data.email,
            password: data.password,
          },
        },
      });
      const response = await post<LoginResponse>(url, payload, configs);
      const { token, user, message } = handleLoginResponse(response);
      if (token && user) {
        setAuthToken(token);
        return Promise.resolve({ user, message });
      } else {
        return Promise.reject(new Error("Invitation failed"));
      }
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const postLogin = async ({ orgId }: { orgId: Organization["id"] | null }): Promise<boolean> => {
    try {
      const getMeResponse = await getMe();
      const user: User = getMeResponse;
      let organization: Organization | null = null;
      if (orgId) {
        organization = await getOrganizationById(orgId);
      }
      setAuthSessionData({ user, organization });
      return Promise.resolve(true);
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const logout = async (): Promise<boolean> => {
    try {
      const { url, configs } = await getRequestConfigs({ endpointKey: "logout" });
      const response = await deleteMethod<boolean>(url, configs);
      if (response.status === 200) {
        return Promise.resolve(true);
      } else {
        return Promise.reject(new Error("Logout failed"));
      }
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const getMe = async (): Promise<User> => {
    try {
      const { url, configs } = await getRequestConfigs({ endpointKey: "getMe" });
      const response = await get<AxiosResponse>(url, configs);
      return Promise.resolve(handleGetMeResponse(response));
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const getOrganizationById = async (id: Organization["id"]): Promise<Organization> => {
    try {
      const { url, configs } = await getRequestConfigs({ endpointKey: "organizations" });
      const response = await get<AxiosResponse>(`${url}/${id}`, configs);
      return Promise.resolve(handleOrganizationResponse(response));
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const getOrganizationsList = async (
    params: AxiosRequestConfig["params"]
  ): Promise<OrganizationsResponse> => {
    try {
      const { url, configs } = await getRequestConfigs({
        endpointKey: "organizations",
        requestConfig: {
          params: params,
        },
      });
      const response = await get<OrganizationsResponse>(url, configs);
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const createOrganization = async (orgData: CreateOrganizationPayload["organization"]): Promise<unknown> => {
    try {
      const { url, payload, configs } = await getRequestConfigs({
        endpointKey: "organizations",
        requestPayload: {
          organization: orgData,
        },
      });
      const response = await post<AxiosResponse>(url, payload, configs);
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const toggleArchiveOrganization = async ({
    orgId,
    isCurrentlyArchived,
  }: {
    orgId: Organization["id"];
    isCurrentlyArchived: boolean;
  }): Promise<unknown> => {
    try {
      const { url, configs } = await getRequestConfigs({ endpointKey: "organizations" });
      const response = await patch<AxiosResponse>(
        `${url}/${orgId}/${isCurrentlyArchived ? "unarchive" : "archive"}`,
        {},
        configs
      );
      return Promise.resolve(response.data);
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  const getUsersList = async (params: AxiosRequestConfig["params"]): Promise<UsersResponse> => {
    try {
      const { url, configs } = await getRequestConfigs({
        endpointKey: sessionRole === "admin" ? "users" : "organizations",
        requestConfig: {
          params: params,
        },
      });
      const requestUrl = sessionRole === "admin" ? url : `${url}/${sessionOrganization?.id}/users`;
      const response = await get<UsersResponse>(requestUrl, configs);
      return Promise.resolve(handleGetUsersResponse(response.data));
    } catch (error: unknown) {
      handleError(error);
      return Promise.reject(error);
    }
  };

  return {
    checkHealth,
    sendResetPasswordEmail,
    createPassword,
    login,
    postLogin,
    logout,
    getMe,
    getOrganizationsList,
    getOrganizationById,
    createOrganization,
    toggleArchiveOrganization,
    getUsersList,
  };
}
