import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FeaturePage } from "src/components/layout/page-templates";
import { AddNewButton } from "src/components/ui-components/buttons";
import { InfoLabel } from "src/components/ui-components/info";
import { Pagination, PaginationInfo } from "src/components/ui-components/pagination";
import { Table } from "src/components/ui-components/table";
import { useCoreApi } from "src/hooks";
import { useAppSession } from "src/hooks";
import type { Organization, Pagy } from "src/lib/types";

export type TableRowAction = {
  key: string;
  content: string | React.ReactNode;
  onClick: () => void;
};

export const OrganizationsPage = () => {
  const navigate = useNavigate();
  const { diveInToOrganization } = useAppSession();
  const { getOrganizationsList, toggleArchiveOrganization, getOrganizationById } = useCoreApi();
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState<Organization[] | null>(null);
  const [pagy, setPagy] = useState<Pagy | null>(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    getOrganizationsList({
      status: "all",
      page,
    })
      .then(({ organizations, pagy }) => {
        setPagy(pagy);
        setOrganizations(organizations);
      })
      .catch((error) => {
        console.error("Failed to fetch organizations", error);
      })
      .finally(() => setLoading(false));
  }, [page]);

  const handleArchiveOrganization = (organizationId: number, isArchived: boolean) => {
    if (
      !window.confirm(`Are you sure you want to ${isArchived ? "unarchive" : "archive"} this organization?`)
    ) {
      return;
    }
    setLoading(true);
    toggleArchiveOrganization({
      orgId: organizationId,
      isCurrentlyArchived: isArchived,
    })
      .then(() => {
        setOrganizations((orgs) => {
          if (orgs) {
            return orgs.map((org) => {
              if (org.id === organizationId) {
                return { ...org, archived: !org.archived };
              }
              return org;
            });
          }
          return orgs;
        });
      })
      .catch((error) => {
        console.error("Failed to archive organization", error);
      })
      .finally(() => setLoading(false));
  };

  const handleDiveIn = (organizationId: number) => {
    setLoading(true);
    getOrganizationById(organizationId)
      .then((org) => {
        diveInToOrganization(org);
        navigate("/org/customers");
      })
      .catch((error) => {
        console.error("Failed to fetch organization", error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <FeaturePage
      singular="Organization"
      plural="Organizations"
      total={pagy?.count}
      description="Manage your organizations"
      actions={<AddNewButton singular="Organization" onClick={() => navigate("/admin/organizations/new")} />}>
      <PaginationInfo pagy={pagy} />
      <Table
        perPage={10}
        loading={loading && organizations === null}
        cols={[
          { key: "name", content: "Name" },
          { key: "owner_email", content: "Owner" },
          { key: "plan", content: "Plan" },
          { key: "archived", content: "Status" },
        ]}
        rows={
          organizations
            ? organizations.map((org) => ({
                id: org.id,
                cells: [
                  {
                    key: "name",
                    content: (
                      <>
                        <p className="font-medium">{org.name}</p>
                        <p className="text-xs text-gray-400">/{org.slug}</p>
                      </>
                    ),
                  },
                  {
                    key: "owner_email",
                    content: (
                      <a className="link-colors flex items-center" href={`mailto:${org.owner_email}`}>
                        <EnvelopeIcon className="h-4" />
                        <span className="ml-1">{org.owner_email}</span>
                      </a>
                    ),
                  },
                  {
                    key: "plan",
                    content: <InfoLabel className="capitalize">{org.plan}</InfoLabel>,
                  },
                  {
                    key: "archived",
                    content: (
                      <InfoLabel color={org.archived ? "danger" : "success"}>
                        {org.archived ? "archived" : "active"}
                      </InfoLabel>
                    ),
                  },
                ],
                actions: [
                  {
                    key: "archive",
                    node: "button",
                    title: org.archived ? "Restore" : "Archive",
                    action: () => handleArchiveOrganization(org.id, org.archived),
                  },
                  {
                    key: "dive_in",
                    node: "button",
                    title: "Dive in",
                    action: () => handleDiveIn(org.id),
                  },
                ],
              }))
            : []
        }
      />
      <Pagination className="mt-4" pagy={pagy} onPageChange={(p) => setPage(p)} />
    </FeaturePage>
  );
};
