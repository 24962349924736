import { useEffect } from "react";

import { AuthLogoutScreen } from "src/components/features/auth/screens/AuthScreens";
import { useCoreApi } from "src/hooks";
import { useAppSession } from "src/hooks";

export const LogoutPage = () => {
  const { logout } = useCoreApi();
  const { destroyAuthSession } = useAppSession();

  useEffect(() => {
    logout()
      .then(() => {
        console.debug("User logged out");
      })
      .catch((error) => {
        console.error("Failed to logout user", error);
      })
      .finally(() => {
        destroyAuthSession();
      });
  }, [destroyAuthSession]);

  return <AuthLogoutScreen />;
};
