import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { isAxiosError } from "axios";

import AuthFormContainer from "src/components/features/auth/components/AuthFormContainer";
import { AuthScreen } from "src/components/features/auth/screens/AuthScreens";
import { CreatePasswordForm } from "src/components/forms";
import type { CreatePasswordFormData } from "src/components/forms";
import { Button } from "src/components/ui-components/buttons";
import { useCoreApi } from "src/hooks";
import { useQueryParams } from "src/hooks";
import type { CreatePasswordPayload } from "src/lib/types";

type TranslationItem = {
  i18nKey: string;
  defaults: string;
};

const getTitles = (
  isInvitation: boolean
): { initialTitle: TranslationItem; disabledTitle: TranslationItem } => {
  return {
    initialTitle: {
      i18nKey: isInvitation ? "todo_add_this_key" : "todo_add_this_key",
      defaults: isInvitation
        ? "Create your first password to accept this invitation"
        : "Create a new password",
    },
    disabledTitle: {
      i18nKey: isInvitation ? "todo_add_this_key" : "todo_add_this_key",
      defaults: isInvitation ? "You can't accept this invitation" : "You can't perform this operation",
    },
  };
};

const getErrorMessage = (status?: number): TranslationItem => {
  switch (status) {
    case 401:
      return { i18nKey: "todo_add_this_key", defaults: "Your token is not valid" };
    case 422:
      return { i18nKey: "todo_add_this_key", defaults: "Please provide a valid data" };
    default:
      return { i18nKey: "todo_add_this_key", defaults: "Something went wrong" };
  }
};

export const CreatePasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { createPassword } = useCoreApi();
  const { getParam } = useQueryParams();
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const token = getParam("token") || "";
  const isInvitation = location.pathname === "/auth/invite";

  useEffect(() => {
    if (!token) {
      navigate("/auth/login");
    }
  }, []);

  const onSubmit = (data: CreatePasswordFormData) => {
    setLoading(true);
    const payload: CreatePasswordPayload = {
      token: token,
      ...data,
    };
    createPassword({ endpointKey: isInvitation ? "invitations" : "resetPassword", data: payload })
      .then(({ user }) => {
        navigate({ pathname: "/auth/login", search: "?postLogin=true" }, { state: { user } });
      })
      .catch((error: unknown) => {
        const status = isAxiosError(error) ? error.response?.status : null;
        const message = isAxiosError(error) ? error.response?.data.error : null;
        switch (status) {
          case 401:
            setMessage(message || getErrorMessage(status).defaults);
            setDisabled(true);
            break;
          case 422:
            setMessage(message || getErrorMessage(status).defaults);
            break;
          default:
            setDisabled(true);
            setMessage(message || getErrorMessage().defaults);
            break;
        }
      })
      .finally(() => setLoading(false));
  };

  const { initialTitle, disabledTitle } = getTitles(isInvitation);

  return (
    <AuthScreen>
      <AuthFormContainer
        title={disabled ? disabledTitle.defaults : initialTitle.defaults}
        subTitle={<span className="text-red-500">{message}</span>}>
        {disabled ? (
          <div className="flex justify-center pt-5">
            <Button onClick={() => navigate({ pathname: "/auth/login" }, { replace: true })}>Login</Button>
          </div>
        ) : (
          <CreatePasswordForm onSubmit={onSubmit} disabled={loading || disabled} />
        )}
      </AuthFormContainer>
    </AuthScreen>
  );
};
