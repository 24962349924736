import classNames from "classnames";

import CircleSpinner from "./CircleSpinner";
import { FullScreenLoaderType } from "src/store/slices/loadingSlice";

export const FullScreenLoader = ({
  title,
  subTitle,
  className,
}: FullScreenLoaderType & {
  className?: string;
}) => {
  return (
    <div className={classNames("fixed inset-0 flex items-center justify-center", className)}>
      <div className="absolute inset-0 z-0 bg-white opacity-90 dark:bg-black" />
      <div className="relative z-10 flex flex-col items-center justify-center text-black dark:text-white">
        <h2 className="mb-4 text-center text-3xl lg:text-4xl">{title || "Loading"}</h2>
        <p className="mb-4 text-center">{subTitle || "Please wait"}</p>
        <div>
          <CircleSpinner size="medium" />
        </div>
      </div>
    </div>
  );
};

export default FullScreenLoader;
