import React from "react";
import { useLocation } from "react-router-dom";

export function useQueryParams() {
  const { search } = useLocation();
  const params = React.useMemo(() => new URLSearchParams(search), [search]);

  const getObject = () => {
    const obj: Record<string, string> = {};
    params.forEach((value: string, name: string) => {
      obj[name] = value.replaceAll('"', "").replaceAll("'", "");
    });
    return obj;
  };

  const get = (name: string) => {
    const value = params.get(name);
    if (value === null || typeof value !== "string") return null;
    return value.replaceAll('"', "").replaceAll("'", "");
  };

  const getArray = (name: string, separator = "") => {
    const value = params.get(name);
    if (value === null || typeof value !== "string") return null;
    return value
      .replace(/"'/g, "")
      .split(separator)
      .filter((v) => v !== "");
  };

  return {
    params,
    getParam: get,
    getArrayParam: getArray,
    getObjectFromParams: getObject,
  };
}
