import React from "react";

import classNames from "classnames";

type FormLabelProps = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
> & {
  className?: string;
  required?: boolean;
};

export const FormLabel = ({ children, className, required, ...props }: FormLabelProps) => {
  return (
    <label className={classNames("mb-2 block", className)} {...props}>
      <span>{children}</span>
      {required && <span className="ml-1 text-red-500">*</span>}
    </label>
  );
};
