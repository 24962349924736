import { Navigate, useLocation } from "react-router-dom";

import { useQueryParams } from "src/hooks";
import { getQueryStringFromObject } from "src/lib/utils";
import { useAppSelector } from "src/store";
import { getSessionIsAuthenticatedSelector, getSessionRoleSelector } from "src/store/selectors";

export const AppProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = useAppSelector(getSessionIsAuthenticatedSelector);
  const { getObjectFromParams } = useQueryParams();
  const location = useLocation();

  if (!isAuthenticated) {
    const getSearch = () => {
      const notAllowedPaths = ["/auth/logout"];
      const pathname = location.pathname;
      if (pathname === "/" || notAllowedPaths.includes(pathname)) {
        return "";
      } else {
        const { username, ...rest } = getObjectFromParams() as Record<string, string>;
        const params: Record<string, string> = {
          redirectAfterLogin: pathname + "?" + new URLSearchParams(rest).toString(),
        };
        if (username) {
          params.username = username;
        }
        return getQueryStringFromObject(params);
      }
    };
    return <Navigate to={{ pathname: "/auth/login", search: getSearch() }} state={{ from: location }} />;
  }

  return children;
};

export const AppPublicRoute = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = useAppSelector(getSessionIsAuthenticatedSelector);
  const sessionRole = useAppSelector(getSessionRoleSelector);
  const { getParam } = useQueryParams();
  const location = useLocation();

  if (isAuthenticated) {
    const defaultPath = `/${sessionRole}/dashboard`;
    const savedRedirect = getParam("redirectAfterLogin") || defaultPath;
    const parts = savedRedirect.split("?");
    const pathname = parts[0];
    const search = parts.length > 1 ? "?" + parts[1] : "";
    return (
      <Navigate
        to={{
          pathname,
          search,
        }}
        state={{ from: location }}
      />
    );
  }

  return children;
};
