import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAppGlobalLayer } from "src/hooks";

export type DropdownItem =
  | { key: string; node: "button"; title: string | React.ReactNode; action: () => void }
  | { key: string; node: "link"; title: string | React.ReactNode; to: string };

export type DropDownMenuProps = {
  items: DropdownItem[];
  position: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
};

export const DropDownMenu = ({ items, position }: DropDownMenuProps) => {
  return (
    <div
      id="GLOBAL_DROPDOWN"
      className="fixed z-10 mt-2 min-w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in dark:bg-gray-700"
      style={{ ...position }}>
      <div className="py-1">
        {items.map((item) => (
          <div key={item.key}>
            <div className="flex transform items-center text-sm capitalize text-gray-600 transition-colors duration-300 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-900 dark:hover:text-white">
              {item.node === "button" ? (
                <button
                  className="w-full whitespace-nowrap px-4 py-2 text-left"
                  type="button"
                  onClick={item.action}>
                  {item.title}
                </button>
              ) : (
                <Link to={item.to} className="w-full px-4 py-2 text-left">
                  {item.title}
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const DropdownTrigger = ({ items }: { items: DropdownItem[] }) => {
  const { toggleDropdown } = useAppGlobalLayer();
  const triggerRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleResize = () => toggleDropdown(null);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [toggleDropdown]);

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (triggerRef.current) {
      const buttonRect = e.currentTarget.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      // window quadrant for positioning
      let position: { top?: number; bottom?: number; left?: number; right?: number } = {
        top: buttonRect.bottom,
        right: windowWidth - buttonRect.right,
      };
      if (buttonRect.top < windowHeight * 0.7) {
        if (buttonRect.left > windowWidth / 2) {
          // top-right
          position = { top: buttonRect.bottom, right: windowWidth - buttonRect.right };
        } else {
          // top-left
          position = { top: buttonRect.bottom, left: buttonRect.left };
        }
      } else {
        if (buttonRect.left > windowWidth / 2) {
          // bottom-right
          position = { bottom: windowHeight - buttonRect.top, right: windowWidth - buttonRect.right };
        } else {
          // bottom-left
          position = { bottom: windowHeight - buttonRect.top, left: buttonRect.left };
        }
      }
      toggleDropdown({ items, position }, triggerRef.current);
    }
  };

  return (
    <button
      ref={triggerRef}
      data-role="global-dropdown-trigger"
      onClick={onClick}
      className="text-dark inline px-1 hover:text-blue-500 focus:text-blue-500 dark:text-white">
      <EllipsisVerticalIcon className="h-6" />
    </button>
  );
};
