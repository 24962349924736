import { PlusCircleIcon } from "@heroicons/react/24/solid";

import { Button } from "./Button";
import type { ButtonProps } from "./Button";

type AddNewButtonProps = Omit<ButtonProps, "children"> & {
  singular: string;
};

export const AddNewButton = ({ singular, ...rest }: AddNewButtonProps) => {
  return (
    <Button {...rest}>
      <PlusCircleIcon className="h-5" />
      <span>Add {singular}</span>
    </Button>
  );
};
