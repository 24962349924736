import { Route, Routes, Navigate } from "react-router-dom";

import {
  LoginPage,
  LogoutPage,
  ResetPasswordPage,
  CreatePasswordPage,
} from "src/components/features/auth/pages";
import { NotFoundPage } from "src/components/pages";
import { AppPublicRoute, AppProtectedRoute } from "src/router/AppRouting";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <AppPublicRoute>
            <LoginPage />
          </AppPublicRoute>
        }
      />
      <Route
        path="/invite"
        element={
          <AppPublicRoute>
            <CreatePasswordPage />
          </AppPublicRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <AppPublicRoute>
            <ResetPasswordPage />
          </AppPublicRoute>
        }
      />
      <Route
        path="/create-password"
        element={
          <AppPublicRoute>
            <CreatePasswordPage />
          </AppPublicRoute>
        }
      />
      <Route
        path="/logout"
        element={
          <AppProtectedRoute>
            <LogoutPage />
          </AppProtectedRoute>
        }
      />
      <Route path="/" element={<Navigate to="/auth/login" />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
