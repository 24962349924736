import classNames from "classnames";

export type InfoLabelProps = {
  color?: "primary" | "accent" | "success" | "warning" | "danger";
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLSpanElement>;

export const InfoLabel = ({ color = "primary", className, children, ...rest }: InfoLabelProps) => {
  return (
    <span
      {...rest}
      className={classNames(
        "rounded-full px-3 py-1 text-xs",
        color === "primary" && "primary-info-label-colors",
        color === "accent" && "accent-info-label-colors",
        color === "success" && "success-info-label-colors",
        color === "warning" && "warning-info-label-colors",
        color === "danger" && "danger-info-label-colors",
        className
      )}>
      {children}
    </span>
  );
};
