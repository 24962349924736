import { Route, Routes, Navigate } from "react-router-dom";

import OrganizationDashboard from "src/components/features/dashboard/OrganizationDashboard";
import { UsersPage } from "src/components/features/users/pages";
import { NotFoundPage } from "src/components/pages";
import { useAppSelector } from "src/store";
import { getSessionRoleSelector } from "src/store/selectors";

export const OrganizationRoutes = () => {
  const sessionRole = useAppSelector(getSessionRoleSelector);

  return sessionRole === "org" ? (
    <Routes>
      <Route path="/" element={<Navigate to="/org/dashboard" />} />
      <Route path="/dashboard" element={<OrganizationDashboard />} />
      <Route path="/customers" element={<UsersPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  ) : (
    <NotFoundPage />
  );
};
