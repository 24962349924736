import { useAppDispatch } from "src/store";
import {
  setGlobalLoadingAction,
  setAppLoadingAction,
  setInitialAppLoadingAction,
} from "src/store/slices/loadingSlice";
import type { FullScreenLoaderType } from "src/store/slices/loadingSlice";

type SetFullScreenLoadingOptions =
  | {
      title?: FullScreenLoaderType["title"];
      subTitle?: FullScreenLoaderType["subTitle"];
    }
  | undefined;

const defaultOptions: SetFullScreenLoadingOptions = {
  title: null,
  subTitle: null,
};

export const useLoading = () => {
  const dispatch = useAppDispatch();

  return {
    setGlobalLoading: (isLoading: boolean) => dispatch(setGlobalLoadingAction(isLoading)),
    startFullScreenLoading: (options?: SetFullScreenLoadingOptions) => {
      const { title, subTitle } = { ...defaultOptions, ...options };
      dispatch(
        setAppLoadingAction({
          globalLoading: true,
          fullScreenLoading: { title: title || null, subTitle: subTitle || null },
        })
      );
    },
    stopFullScreenLoading: () => dispatch(setInitialAppLoadingAction()),
  };
};
