import PageWithSideNav from "src/components/layout/components/PageWithSideNav";

type CustomerLayoutProps = {
  children: React.ReactNode;
};

export function CustomerLayout({ children }: CustomerLayoutProps) {
  return <PageWithSideNav>{children}</PageWithSideNav>;
}

export default CustomerLayout;
