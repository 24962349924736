import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import classNames from "classnames";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "src/components/ui-components/buttons";
import { TextInput } from "src/components/ui-components/inputs/TextInput";
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from "src/lib/constants";

function getValidationSchema() {
  return z
    .object({
      password: z
        .string()
        .min(PASSWORD_MIN_LENGTH, `Password must be at least ${PASSWORD_MIN_LENGTH} characters`)
        .max(PASSWORD_MAX_LENGTH, `Password must be at most ${PASSWORD_MAX_LENGTH} characters`),
      password_confirmation: z
        .string()
        .min(PASSWORD_MIN_LENGTH, `Password must be at least ${PASSWORD_MIN_LENGTH} characters`)
        .max(PASSWORD_MAX_LENGTH, `Password must be at most ${PASSWORD_MAX_LENGTH} characters`),
    })
    .superRefine(({ password_confirmation, password }, ctx) => {
      if (password_confirmation !== password) {
        ctx.addIssue({
          code: "custom",
          message: "The password confirmation does not match the password",
          path: ["password_confirmation"],
        });
      }
    });
}

export type CreatePasswordFormData = {
  password: string;
  password_confirmation: string;
};

export type CreatePasswordFormProps = {
  onSubmit: (data: CreatePasswordFormData) => void;
  disabled?: boolean;
  className?: string;
};

export const CreatePasswordForm = ({ onSubmit, className, disabled }: CreatePasswordFormProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(getValidationSchema()),
    mode: "onTouched",
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
  });

  const submit: SubmitHandler<CreatePasswordFormData> = (data) => onSubmit(data);

  return (
    <form onSubmit={handleSubmit(submit)} noValidate className={classNames("w-full", className)}>
      <fieldset disabled={disabled}>
        <div className="mb-4">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                required
                type={isPasswordVisible ? "text" : "password"}
                label="Password"
                subText="Create a password that is at least 8 characters long"
                error={errors.password}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller
            name="password_confirmation"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                required
                type={isPasswordVisible ? "text" : "password"}
                label="Password confirmation"
                subText="Type your password again to confirm it"
                error={errors.password_confirmation}
              />
            )}
          />
        </div>
      </fieldset>
      <div className="mb-8 flex items-center justify-between space-x-2">
        <button
          type="button"
          className="text-sm text-blue-500 underline"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
          {isPasswordVisible ? "Hide" : "Show"} password
        </button>
        <span className="inline-block h-6 items-center space-x-2">
          {isPasswordVisible ? <EyeIcon className="h-full" /> : <EyeSlashIcon className="h-full" />}
        </span>
      </div>
      <div className="flex justify-center">
        <Button disabled={!isValid || disabled} type="submit" size="md">
          Create password
        </Button>
      </div>
    </form>
  );
};
