import React, { useState } from "react";

import classNames from "classnames";

import Header from "src/components/layout/components/Header";
import NavMenu from "src/components/layout/components/NavMenu";
import { saveNavbarState, getSavedNavbarState } from "src/lib/utils";

function PageWithSideNav({ children }: { children: React.ReactNode }) {
  const [isCollapsed, setIsCollapsed] = useState(getSavedNavbarState());

  return (
    <div className="relative flex h-dvh flex-col overflow-x-hidden md:flex-row">
      <NavMenu
        onCollapse={() => {
          const newState = !isCollapsed;
          setIsCollapsed(newState);
          saveNavbarState(newState);
        }}
        isCollapsed={isCollapsed}
        className={classNames(isCollapsed ? "md:w-[56px]" : "md:w-[256px]")}
      />
      <div
        className={classNames(
          "flex min-h-dvh w-full flex-col",
          isCollapsed ? "md:w-[calc(100%-56px)]" : "md:w-[calc(100%-256px)]"
        )}>
        <Header className="sticky left-0 top-0 z-10 h-14 w-full" />
        <div className="main-bg-color flex-1 pb-[64px] md:pb-0">{children}</div>
      </div>
    </div>
  );
}

export default PageWithSideNav;
